<template>
  <v-app id="my-id">
    <v-app-bar
      app
      color="red darken-4"
      dark
      dense
    >
      <!-- <div class="d-flex align-center">
        <v-img
          alt="Innovate Logo"
          contain
          :src="images.logo"
          transition="scale-transition"
          width="50"
        />
      </div> -->
      <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp" class="white--text font-weight-bold">Compadres Autos LLC</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn x-small text color="white" href="https://www.google.com/maps/dir/?api=1&destination=808+N+16th+Ave.+Yakima,+WA.+98901" class="mx-1 px-1 my-4">
        Visit us
        <v-icon light>mdi-map-marker</v-icon>
      </v-btn>
      <v-btn x-small text color="white" href="tel:5098234009" class="mx-1 px-1 my-4">
        Call
        <v-icon light>mdi-phone</v-icon>
      </v-btn>
      <v-btn x-small text color="white" href="sms:5099410908" class="mx-1 px-1 my-4">
        Text
        <v-icon>mdi-cellphone-text</v-icon>
      </v-btn>
      <v-btn x-small text color="white" href="mailto:chris@compadresautos.com" class="mx-1 px-1 my-4">
        Email
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <Main/>
    </v-main>
  </v-app>
</template>

<script>
import Main from './components/Main';

export default {
  name: 'App',

  components: {
    Main,
  },

  data: () => ({
    images: {
      logo: require('./assets/logo.png')
    }
  }),
};
</script>
