import Vue from 'vue'
// import { component } from 'vue/types/umd';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import CarListing from './components/CarListing'
import axios from 'axios'

Vue.config.productionTip = false
Vue.component('CarListing', CarListing);
Vue.prototype.$http = axios

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
