<template>
  <v-card class="mx-auto">
    <v-card-title class="justify-center pb-0" style="font-size: 1em">
      {{ `${car.year} ${car.make} ${car.model}` }}
    </v-card-title>
    <v-row justify="center" class="pb-3">
      <v-chip small outlined label> $ {{ car.price }} </v-chip>
    </v-row>
    <v-dialog max-width="700" v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" :scrollable="$vuetify.breakpoint.mobile">
      <template v-slot:activator="{ on, attrs }">
        <v-carousel hide-delimiters height="200" style="cursor: pointer">
          <v-carousel-item
            v-bind="attrs"
            v-on="on"
            v-for="(image, image_i) in car.images"
            :key="image_i"
            :src="image"
          >
          </v-carousel-item>
        </v-carousel>
      </template>
      <v-card>
        <v-btn @click="dialog = false" icon class="ma-2 pa-0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="justify-center pt-0" style="font-size: 1.5em">
          {{ `${car.year} ${car.make} ${car.model}` }}
          <v-chip small outlined label class="ma-2 justify-right">
            $ {{ car.price }}
          </v-chip>
        </v-card-title>
        <v-carousel hide-delimiters height="400">
          <v-carousel-item
            v-for="(image, image_i) in car.images"
            :key="image_i"
          >
            <v-img eager contain height="400" :src="image"></v-img>
            
          </v-carousel-item>
        </v-carousel>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-for="(attribute, attribute_i) in car.attributes"
                :key="attribute_i"
                class="pa-1"
              >
                <v-chip small outlined label>
                  <b>{{ attribute.name }}</b
                  >:&nbsp;{{ attribute.value }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-container> <b>Options</b>: {{ car.options.join(', ') }} </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card-text class="text--primary">
      {{ car.description }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      model: 0,
    };
  },
  name: "CarListing",
  props: ["car"],
};
</script>