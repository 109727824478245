<template>
  <div>
    <!-- <v-parallax :src="images.logo1" height="700">
    <p class="text-h3">About Us</p>
    <p class="text-body-1">
    At Innovate Motors, customer satisfaction is the key to our success. We offer a simple and stress-free shopping experience with a wide selection of quality used cars, trucks, and SUVs. Our team strives for excellence by assuring each customer finds a vehicle that will fit their lifestyle and budget. For quality and service you can trust, shop with Innovate Motors, LLC.
    </p>
    </v-parallax> -->
    <v-container fluid class="grey lighten-3">
      <v-row>
      <v-col cols="12" sm="12" md="12" lg="6">
        <v-img contain :src="images.logo"></v-img>
        <!-- <v-parallax :src="images.logo2" height="500"/> -->
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" style="margin-top: auto; margin-bottom: auto">
        <p class="red--text text--darken-3 text-h3">About Us</p>
          <p class="text-body-1">
          At Compadres Autos, customer satisfaction is the key to our success. We offer a simple and stress-free shopping experience with a wide selection of quality used cars, trucks, and SUVs. Our team strives for excellence by assuring each customer finds a vehicle that will fit their lifestyle and budget. For quality and service you can trust, shop with Compadres Autos, LLC.
          </p>
      </v-col>
    </v-row>
    </v-container>
    <v-container fluid class="grey lighten-5 pt-5" fill-height>
      <!-- <v-divider></v-divider> -->
      <v-subheader class="mx-auto">
      <p class="text-h5 font-weight-black mb-0">&nbsp;&nbsp;&nbsp;Inventory&nbsp;&nbsp;&nbsp;</p>
      </v-subheader>
      <!-- <v-divider></v-divider> -->
      <v-row>
        <v-col
          v-for="(car, car_i) in cars"
          :key="car_i"
          cols="12"
          xl="2"
          lg="3"
          md="6"
          sm="6"
          xs="12"
          class="px-6 py-5"
        >
          <CarListing :car=car></CarListing>
        </v-col>
        
      </v-row>
      </v-container>
      <v-footer
      dark
      padless
      >
      <v-card
        width="100%"
        flat
        tile
        class="white--text text-center"
      >
        <v-card-text>
          <v-btn
            v-for="(page, page_i) in social_media_pages"
            :key="page_i"
            :href="page.link"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ page.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          For quality and service you can trust, shop with Compadres Autos, LLC.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Compadres Autos, LLC</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    
    
  </div>
</template>

<style scoped>
.v-parallax__image {
  transform: none !important;
  width: 100% !important;
}
</style>

<script>
  import axios from 'axios'
  export default {
    name: 'HelloWorld',

    data: () => ({
      images: {
        logo: require('../assets/logo.png')
      },
      dialog: false,
      social_media_pages: [
        { icon: 'mdi-facebook', link: 'https://www.facebook.com/search/top?q=Compadres%20Autos%20LLC.'}
      ],
      cars: []
      
    }),

    created () {
      axios.get('/api/listings')
      .then(response => {
        // JSON responses are automatically parsed.
        this.cars = response.data
      })
      .catch(e => {
        this.errors.push(e)
      })
    }
    // mounted() {
    //   axios.get('http://localhost:8081/listings').then(then(response => (this.cars = response)))
    // }
  }
</script>
